import React, { useRef } from 'react';
import styled from 'styled-components';
import { Transition, TransitionGroup } from 'react-transition-group';
import { breakpoints, spacing } from '@naf/theme';
import { CardGrid } from '@naf/card-grid';
import { GridCol, GridRow } from '@naf/grid';
import { fill } from '@cloudinary/url-gen/actions/resize';
import { AdvancedImage, lazyload } from '@cloudinary/react';
import { Text, TextVariant } from '@naf/text';
import { useCloudinary } from '../../hooks/useCloudinary';
import { StyledLink } from '../styled-link/StyledLink';
import { ArticleType } from '../../../../types/articleType';
import { createNorwegianDateString } from '../../utils/createNorwegianDateString';
import { ArticleLoader } from '../skeleton-loaders/ArticleLoader';
import { AnimationWrapper } from '../skeleton-loaders/AnimationWrapper';
import FinderIllustration from '../icons/DynamicallyImportedFinderIllustration';
import { DocumentTypeLabel } from '../../../../types/testType';
import defaultImage from '../../../assets/images/defaultLocalArticle.svg?url';
import { useDocumentUrlWithNode } from '../../hooks/useDocumentUrl';

interface Props {
  articleItems: Record<string, ArticleType>;
  error?: boolean;
  updating?: boolean;
  showDate?: boolean;
  showNewsLabel?: boolean;
}

const ArticleItemCard = ({
  article,
  showDate = false,
  showNewsLabel = false,
}: {
  article: ArticleType;
  showDate?: boolean;
  showNewsLabel?: boolean;
}) => {
  const {
    image,
    name,
    category,
    ingress,
    meta,
    metaDisplay,
    type,
    localArticleImage,
    paywallDetails,
    abTestExperimentSlug,
  } = article;

  const imageAlt = image?.altText || category?.image?.altText;

  // Link to document
  const documentLink = useDocumentUrlWithNode(article);

  // Check whether the documentLink is overwritten by the document being part of an experiment
  // If it is, route the link via the experiment it is part of.
  const link = abTestExperimentSlug ? `/${abTestExperimentSlug}` : documentLink;

  const cld = useCloudinary();
  const publishedDate = createNorwegianDateString(new Date(meta?.publishAt));
  const itemImage =
    (image &&
      image.publicId &&
      cld.image(image.publicId).resize(fill().width(768).height(432)).quality('auto:eco').format('auto')) ||
    undefined;

  const localImage = localArticleImage?.source === 'NafIllustration' ? defaultImage : localArticleImage?.url || '';

  return (
    <CardGridWrapper s="12" m="12" l="4" xl="4" key={`article-item-${name}`}>
      <StyledLink to={link}>
        <CardGrid
          headerTag="h2"
          title={name}
          img={{
            src: localImage,
            alt: localArticleImage?.caption || '',
          }}
          advancedImage={
            itemImage && (
              <AdvancedImage
                alt={imageAlt || ''}
                cldImg={itemImage}
                plugins={[lazyload({ rootMargin: '10px 20px 10px 30px', threshold: 0.25 })]}
              />
            )
          }
          date={showDate && !metaDisplay?.hidePublicationDate ? publishedDate : ''}
          label={
            showNewsLabel && type === DocumentTypeLabel.nafnoNewsArticle
              ? {
                  text: 'NYHET',
                  size: 'small',
                  variant: 'signature',
                }
              : undefined
          }
          memberLabel={
            paywallDetails
              ? {
                  labelText: paywallDetails?.labelText || 'For medlemmer',
                  inverted: true,
                  isEligible: paywallDetails?.isAccessibleForUser,
                }
              : undefined
          }
        >
          {ingress}
        </CardGrid>
      </StyledLink>
    </CardGridWrapper>
  );
};

const ArticleGrid = ({ articleItems, error, updating, showDate = false, showNewsLabel = false }: Props) => {
  const articles = articleItems && Object.values(articleItems);
  const placeholderList = [...Array(12).keys()];
  const placeholderGridRef = useRef<HTMLDivElement | null>(null);
  const gridRef = useRef<HTMLDivElement | null>(null);
  const articlesCount = articleItems ? Object.values(articleItems).length : null;

  return (
    <>
      <TransitionGrid>
        {updating && (
          <Transition
            nodeRef={placeholderGridRef}
            in={updating}
            timeout={{ enter: 250, exit: 0 }}
            unmountOnExit
            mountOnEnter
          >
            {(state) => (
              <AnimationWrapper ref={placeholderGridRef} $state={state}>
                <GridRow>
                  {placeholderList.map((i) => (
                    <CardGridWrapper s="12" m="12" l="4" xl="4" key={`article-placeholder-item-${i}`}>
                      <ArticleLoader />
                    </CardGridWrapper>
                  ))}
                </GridRow>
              </AnimationWrapper>
            )}
          </Transition>
        )}
        {!updating && !error && articlesCount > 0 && (
          <Transition nodeRef={gridRef} key="data" in={!updating} timeout={250} unmountOnExit mountOnEnter>
            {(state) => (
              <AnimationWrapper ref={gridRef} $state={state}>
                {Object.keys(articles).length > 0 && (
                  <GridRow>
                    {articles.map((articleItem) => (
                      <ArticleItemCard
                        article={articleItem}
                        showDate={showDate}
                        showNewsLabel={showNewsLabel}
                        key={`s-${articleItem.slug}`}
                      />
                    ))}
                  </GridRow>
                )}
              </AnimationWrapper>
            )}
          </Transition>
        )}
      </TransitionGrid>
      {!updating && error ? (
        <GridRow>
          <GridCol s="12" m="12" l="12" xl="12">
            <EmptyMessagesWrapper>
              <Text variant={TextVariant.Header3} tag="h2">
                Det har dessverre oppstått en feil.
              </Text>
              <Text variant={TextVariant.ArticleText}>
                Vennligst prøv igjen senere, eller kontakt kundeservice dersom problemet vedvarer
              </Text>
            </EmptyMessagesWrapper>
          </GridCol>
        </GridRow>
      ) : null}
      {!updating && !error && articles && articlesCount <= 0 ? (
        <GridRow>
          <GridCol s="12" m="12" l="12" xl="12">
            <EmptyMessagesWrapper>
              <FinderIllustration />
              <Text variant={TextVariant.Header3} tag="h2">
                Her var det tomt!
              </Text>
              <Text variant={TextVariant.ArticleText}>Vi kan ikke finne noen artikler med valgt kategori.</Text>
            </EmptyMessagesWrapper>
          </GridCol>
        </GridRow>
      ) : null}
    </>
  );
};
const CardGridWrapper = styled(GridCol)`
  margin-bottom: 30px;
  @media (max-width: ${breakpoints.m}) {
    margin-bottom: 0;
  }
`;

const TransitionGrid = styled(TransitionGroup)`
  grid-column: 1 / -1;
`;

const EmptyMessagesWrapper = styled.div`
  text-align: center;
  padding-top: ${spacing.space48};
  padding-bottom: ${spacing.space64};
  p {
    margin-top: ${spacing.space8};
  }
  h2 {
    margin-bottom: ${spacing.space8};
  }
  div {
    margin: 0 auto;
  }
`;

export default ArticleGrid;
