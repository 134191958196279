import ContentLoader from 'react-content-loader';
import { nafColor } from '@nafcore/theme';
import React from 'react';
import styled from 'styled-components';

export const ArticleLoader = () => (
  <Wrapper>
    <ContentLoader
      uniqueKey="article-loader"
      width="100%"
      viewBox="0 0 450 500"
      preserveAspectRatio="xMidYMid meet"
      backgroundColor={nafColor.neutral.neutral1}
      foregroundColor={nafColor.neutral.neutral2}
    >
      <rect x="0" y="360" rx="4" ry="4" width="100%" height="48" />
      <rect x="0" y="310" rx="3" ry="3" width="100%" height="20" />
      <rect x="0" y="268" rx="3" ry="3" width="50%" height="12" />
      <rect x="0" y="0" rx="0" ry="0" width="100%" height="250" />
    </ContentLoader>
  </Wrapper>
);

const Wrapper = styled.div`
  overflow: hidden;
  height: auto;
`;
